"use strict";
/*!
 *
 *  header.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    var header = document.querySelector('#Header');
    window.addEventListener('scroll', function () {
        if (window.scrollY > 0) {
            header.classList.add('-scrolled');
        }
        else {
            header.classList.remove('-scrolled');
        }
    });
}
exports.default = default_1;
